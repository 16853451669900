import { get } from 'lodash';
// @mui
import { useTheme } from '@mui/material/styles';
// import { Grid, Container, Typography, TableContainer, Table, TableBody, TableRow, TableCell, Avatar, TablePagination } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  exportAbsenteeList,
  exportMeritList,
  getAbsentList,
  getExamAverage,
  getExamDetail,
  getMeritList,
  marksChange,
  searchAbsenteeList,
  searchMeritList,
  sendSMSAbsenteeList,
  sendSMSMeritList,
} from 'src/axiosLib/services/exam/admin';
import Scrollbar from 'src/components/Scrollbar';
import { UserListHead, UserListToolbar } from 'src/sections/@dashboard/user';
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
// import { Stack } from '@mui/system';
// import Button from 'src/theme/overrides/Button';
import { Downloading, Edit } from '@mui/icons-material';
import SearchNotFound from 'src/components/SearchNotFound';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import config from '../util/config';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'Phone', label: 'Phone', alignRight: false },
  { id: 'marksGained', label: 'Marks Gained', alignRight: false },
  { id: 'totalMarks', label: 'Total Marks', alignRight: false },
];

const TABLE_HEAD_A = [
  { id: 'roll', label: 'Roll', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'session', label: 'Session', alignRight: false },
  { id: 'rank', label: 'Rank', alignRight: false },
  { id: 'Phone', label: 'Phone', alignRight: false },
  { id: 'gphone', label: 'Guardian SMS', alignRight: false },
  { id: 'totalMarks', label: 'Total Marks', alignRight: false },
];

export default function ExamResult() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [avgMarks, setAvgMarks] = useState(0);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const examId = urlParams.get('examId');

  const [exam, setExam] = useState();

  const [leaderboard, setLeaderboard] = useState([]);
  const [examSubmitted, setExamSubmitted] = useState(0);
  const [leaderboardPage, setLeaderboardPage] = useState(0);
  const [leaderboardRowsPerPage, setLeaderboardRowsPerPage] = useState(5);
  const [hightestMarks, setHightestMarks] = useState(0);

  const [subjectModal, setSubjectModal] = useState(false);
  const [absentModal, setAbsentModal] = useState(false);

  const [absentBoardPage, setAbsentBoardPage] = useState(0);
  const [absentBoardRowsPerPage, setAbsentboardRowsPerPage] = useState(5);

  const [filterNameLeaderboard, setFilterNameLeaderBoard] = useState('');
  const [filterNameAbsent, setFilterNameAbsent] = useState('');

  const [absentBoard, setAbsentBoard] = useState([]);
  const [examMissed, setExamMissed] = useState(0);

  const [enrolledData, setEnrolledData] = useState(0);

  const [totals, setTotals] = useState(0);
  const [submittedS, setSubmittedS] = useState(0);

  const [marksModal, setMarksModal] = useState(false);
  const [marksData, setMarksData] = useState({});

  const [examDetails, setExamDetails] = useState({});

  const isUserNotFound = leaderboard.length === 0;

  const avgScore = () => {
    try {
      getExamAverage(examId, (error, data) => {
        if (!error) {
          setAvgMarks(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getExamDetailsInfo = () => {
    try {
      getExamDetail(examId, (error, data) => {
        if (!error) {
          setExam(data);
          // console.log(data);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getLeaderboard = () => {
    try {
      getMeritList(examId, leaderboardRowsPerPage, leaderboardPage, (error, data) => {
        if (!error) {
          setLeaderboard(data.leaderboard);
          setExamSubmitted(data.size);
          setTotals(data.size);
          setEnrolledData(data.size);
          // console.log(data.size);
          setHightestMarks(get(data, 'leaderboard[0].totalMarksGained', 0));
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAbsentBoard = () => {
    try {
      getAbsentList(examId, absentBoardRowsPerPage, absentBoardPage, (error, data) => {
        if (!error) {
          setAbsentBoard(data.absent);
          setExamMissed(data.size);
          setSubmittedS(data.size);
        } else {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const sendSmsToMerit = () => {
    setSubjectModal(false);

    console.log(enrolledData);

    sendSMSMeritList(examId, enrolledData, (error, data) => {
      if (!error) {
        alert('SMS SENT SUCCESS ');
      } else {
        alert(error.msg);
      }
    });
  };

  const sendSmsToAbsent = () => {
    setAbsentModal(false);

    sendSMSAbsenteeList(examId, (error, data) => {
      if (!error) {
        alert('SMS SENT SUCCESS');
      } else {
        alert(error.msg);
      }
    });
  };

  const handleEnrollmentChange = (fieldName, event) => {
    setEnrolledData(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setLeaderboardPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLeaderboardRowsPerPage(event.target.value);
    setLeaderboardPage(0);
  };

  const handleFilterByName = (event) => {
    setLeaderboardPage(0);
    setLeaderboardRowsPerPage(5);
    setFilterNameLeaderBoard(event.target.value);
  };

  const handleMarksDataChange = (fieldName, event) => {
    // console.log(event.target.value);
    setMarksData({ ...marksData, [fieldName]: event.target.value });
  };

  const handleChangePageA = (event, newPage) => {
    setAbsentBoardPage(newPage);
  };

  const handleChangeRowsPerPageA = (event) => {
    setAbsentboardRowsPerPage(event.target.value);
    setAbsentBoardPage(0);
  };

  const handleFilterByNameA = (event) => {
    setAbsentBoardPage(0);
    setAbsentboardRowsPerPage(5);
    setFilterNameAbsent(event.target.value);
  };

  useEffect(() => {
    avgScore();
    getExamDetailsInfo();
    // getLeaderboard();
    // getAbsentBoard();
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    getExamDetail(examId, (err, data) => {
      if (!err) {
        setExamDetails(data);
      }
    });
  }, []);

  const downloadExcelMerit = () => {
    const studentData = {
      sortOrder: {
        score: 0,
        name: 0,
        roll: 1,
        rank: 1,
        participatedAt: 0,
      },
    };

    exportMeritList(studentData, examId, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(data.excel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, data.name + '.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  const handleMarksEdit = (row) => {
    const temp = {
      _id: get(row, '_id', ''),
      marks: get(row, 'totalMarksGained', 0),
      prevMarks: get(row, 'totalMarksGained', 0),
      name: get(row, 'userId.username', ''),
    };

    setMarksData(temp);

    setMarksModal(true); //
  };

  const editMarks = () => {
    console.log(marksData);

    marksChange(marksData, (error, data) => {
      if (!error) {
        // console.log(data);

        window.location.reload(false);
      } else {
        alert(error);
      }
    });
  };

  const downloadExcelAbsent = () => {
    const studentData = {
      sortOrder: {
        score: 0,
        name: 0,
        roll: 1,
        rank: 0,
        participatedAt: 0,
      },
    };

    exportAbsenteeList(studentData, examId, (error, data) => {
      if (!error) {
        var XLSX = require('xlsx');
        const worksheet = XLSX.utils.json_to_sheet(data.excel);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, data.name + '.xlsx');
      } else {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    // console.log(leaderboardPage);
    getLeaderboard();
  }, [leaderboardPage, leaderboardRowsPerPage]);

  useEffect(() => {
    getAbsentBoard();
  }, [absentBoardPage, absentBoardRowsPerPage]);

  useEffect(() => {
    if (filterNameLeaderboard.length > 0) {
      searchMeritList(
        examId,
        { string: filterNameLeaderboard || '', limit: leaderboardRowsPerPage || 5 },
        (error, data) => {
          if (!error) {
            setLeaderboard(data.array);
            setExamSubmitted(data.size);
          } else {
            console.log(error);
          }
        }
      );
    }
  }, [filterNameLeaderboard]);

  useEffect(() => {
    if (filterNameAbsent.length > 0)
      searchAbsenteeList(
        examId,
        { string: filterNameAbsent || '', limit: absentBoardRowsPerPage || 5 },
        (error, data) => {
          if (!error) {
            setAbsentBoard(data);
            setExamMissed(data.length);
          } else {
            console.log(error);
          }
        }
      );
  }, [filterNameAbsent]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Average Marks" total={avgMarks} icon={'subway:sms-3'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Hightest Marks" total={hightestMarks} color="info" icon={'fa6-solid:users-rays'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Total Submitted ONLINE + OFFLINE"
              total={examSubmitted}
              color="warning"
              icon={'mdi:register'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Student Attended in %"
              total={(totals / (totals + submittedS)) * 100}
              color="error"
              icon={'mdi:server-security'}
            />
          </Grid>
        </Grid>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>

        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent
            style={{
              backgroundColor: 'beige',
              color: '#800000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Exam Details | CODE: {get(examDetails, 'code', '')}
          </CardContent>
        </Card>

        <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
          <CardContent
            style={{
              backgroundColor: 'beige',
              color: '#800000',
              fontWeight: 'bold',
              fontSize: '20px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button
                  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px', marginRight: '10px' }}
                  onClick={() => {
                    let link = config.WEB_LINK + 'reg/publicExam?examId=' + get(examDetails, '_id', '');

                    alert(link);
                  }}
                >
                  Public Exam Link
                </Button>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button
                  style={{ backgroundColor: '#800000', color: 'white', padding: '10px 40px' }}
                  onClick={() => {
                    let link = config.WEB_LINK + 'webapi/student/exam/qrExam?examCode=' + get(examDetails, 'code', '');

                    alert(link);
                  }}
                >
                  QR code Exam Link
                </Button>
              </Stack>
            </div>
          </CardContent>
        </Card>

        <div
          style={{
            width: '98%',
            marginBottom: '30px',
            border: '2px solid #800000',
            padding: '25px 10px',
            borderRadius: '20px',
          }}
        >
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="name"
                label="Exam Name"
                value={get(examDetails, 'name', '')}
                style={{ width: '100%' }}
                disabled={true}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                name="numberOfQuestions"
                label="Total Questions"
                value={get(examDetails, 'numberOfQuestions', '')}
                style={{ width: '100%' }}
                disabled={true}
              />

              <TextField
                name="marksPerQuestion"
                label="Marks Per Question"
                value={get(examDetails, 'marksPerQuestion', '')}
                style={{ width: '100%' }}
                disabled={true}
              />

              <TextField
                name="negativeMarks"
                label="Negative Marks Per Question"
                value={get(examDetails, 'negativeMarks', '')}
                style={{ width: '100%' }}
                disabled={true}
              />

              <TextField
                name="duration"
                label="Exam Duration in Minutes"
                value={get(examDetails, 'duration', '')}
                style={{ width: '100%' }}
                disabled={true}
              />

              <TextField
                name="teacher"
                label="Teacher"
                value={get(examDetails, 'teacher', '')}
                style={{ width: '100%' }}
                disabled={true}
              />

              <TextField
                name="session"
                label="Session"
                value={get(examDetails, 'session', '')}
                style={{ width: '100%' }}
                disabled={true}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <DateTimePicker
                  label="Exam START Time"
                  name="startTime"
                  value={moment(get(examDetails, 'startTime')).format('yyyy-MM-DD hh:mm A')}
                  inputFormat="DD/MM/YYYY hh:mm A"
                  renderInput={(params) => <TextField {...params} />}
                  PopperProps={{
                    style: { zIndex: 10001 },
                  }}
                  disabled={true}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <DateTimePicker
                  label="Exam END Time"
                  name="endTime"
                  value={moment(get(examDetails, 'endTime')).format('yyyy-MM-DD hh:mm A')}
                  inputFormat="DD/MM/YYYY hh:mm A"
                  renderInput={(params) => <TextField {...params} />}
                  style={{ width: '100%' }}
                  PopperProps={{
                    style: { zIndex: 10001 },
                  }}
                  disabled={true}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '100%' }}>
                <DateTimePicker
                  label="ANSWER Publish Time"
                  name="answerPublishTime"
                  value={moment(get(examDetails, 'answerPublishTime')).format('yyyy-MM-DD hh:mm A')}
                  inputFormat="DD/MM/YYYY hh:mm A"
                  renderInput={(params) => <TextField {...params} />}
                  PopperProps={{
                    style: { zIndex: 10001 },
                  }}
                  disabled={true}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
        </div>

        <Card>
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}> </Stack> */}
          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <UserListToolbar filterName={filterNameLeaderboard} onFilterName={handleFilterByName} />

            <Button
              variant="contained"
              onClick={(e) => setSubjectModal(true)}
              style={{ color: 'white', backgroundColor: '#d53624', width: '30%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              SEND SMS TO MERIT LIST
            </Button>

            <Button
              variant="contained"
              onClick={(e) => {
                downloadExcelMerit();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              DOWNLOAD
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  // order={order}
                  // orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={leaderboard.length}
                />
                <TableBody>
                  {leaderboard.map((row, i) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'userId.roll', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'userId.username', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'rank', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'userId.phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'totalMarksGained', '')}</TableCell>
                        <TableCell align="left"> {get(exam, 'totalMarks', '')}</TableCell>

                        <TableCell align="left">
                          {
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleMarksEdit(row);
                              }}
                              style={{ color: '#880ED4', backgroundColor: 'white' }}
                            >
                              <Edit style={{ color: '#880ED4', marginRight: 5 }} /> EDIT MARKS
                            </Button>
                          }
                        </TableCell>

                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, 'userId._id')}
                            >
                              VIEW STUDENT
                            </Button>
                          }
                        </TableCell>

                        <TableCell align="left">
                          {
                            <Button
                              variant="contained"
                              style={{ backgroundColor: '#800000', color: 'white' }}
                              component={RouterLink}
                              to={
                                '/dashboard/checkUserGivenAnswers/?examId=' +
                                get(examDetails, '_id', '') +
                                '&userId=' +
                                get(row, 'userId._id')
                              }
                            >
                              VIEW ANSWERS
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        {/* <SearchNotFound searchQuery={filterNameLeaderboard} /> */}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={examSubmitted}
            rowsPerPage={leaderboardRowsPerPage}
            page={leaderboardPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={10}>
          {' '}
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-around" spacing={5}>
            <UserListToolbar filterName={filterNameAbsent} onFilterName={handleFilterByNameA} />

            <Button
              variant="contained"
              onClick={(e) => setAbsentModal(true)}
              style={{ color: 'white', backgroundColor: '#d53624', width: '30%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              SEND SMS TO ABSENT LIST
            </Button>

            <Button
              variant="contained"
              onClick={(e) => {
                downloadExcelAbsent();
              }}
              style={{ color: 'white', backgroundColor: 'blue', width: '20%', marginRight: 15 }}
            >
              <Downloading style={{ color: 'white', marginRight: 5 }} />
              DOWNLOAD
            </Button>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD_A} rowCount={absentBoard.length} />
                <TableBody>
                  {absentBoard.map((row, i) => {
                    return (
                      <TableRow hover key={get(row, '_id', '')} tabIndex={-1}>
                        <TableCell align="left"> {get(row, 'roll', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'username', '')}</TableCell>
                        <TableCell align="left"> ABSENT</TableCell>
                        <TableCell align="left"> {get(row, 'phone', '')}</TableCell>
                        <TableCell align="left"> {get(row, 'guardianSMS', '')}</TableCell>
                        <TableCell align="left"> {get(exam, 'totalMarks', '')}</TableCell>
                        <TableCell align="left">
                          {' '}
                          {
                            <Button
                              variant="contained"
                              component={RouterLink}
                              to={'/dashboard/register_student/?std=' + get(row, '_id')}
                            >
                              VIEW STUDENT
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterNameAbsent} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={examMissed}
            rowsPerPage={absentBoardRowsPerPage}
            page={absentBoardPage}
            onPageChange={handleChangePageA}
            onRowsPerPageChange={handleChangeRowsPerPageA}
          />
        </Card>

        <div>
          <Dialog open={subjectModal} onClose={(e) => setSubjectModal(false)}>
            <DialogTitle>Send SMS to Merit List?</DialogTitle>

            <DialogContent>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                {' '}
              </Stack>
              <TextField
                name="name"
                label="Total Exam Given (Editable)"
                style={{ width: '100%' }}
                value={enrolledData}
                onChange={(e) => {
                  handleEnrollmentChange('enrolled', e);
                }}
                required
              />
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                onClick={(e) => setSubjectModal(false)}
                style={{ color: 'white', backgroundColor: 'red' }}
              >
                CANCEL
              </Button>
              <Button variant="contained" onClick={sendSmsToMerit} style={{ color: 'white', backgroundColor: 'blue' }}>
                SEND
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <div>
          <Dialog open={absentModal} onClose={(e) => setAbsentModal(false)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogTitle>Send SMS to Absent List?</DialogTitle>

            <DialogActions>
              <Button
                variant="contained"
                onClick={(e) => setAbsentModal(false)}
                style={{ color: 'white', backgroundColor: 'red' }}
              >
                CANCEL
              </Button>
              <Button variant="contained" onClick={sendSmsToAbsent} style={{ color: 'white', backgroundColor: 'blue' }}>
                SEND
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* MARKS MODAL*/}
        <div>
          <Dialog open={marksModal} onClose={() => setMarksModal(false)}>
            <DialogTitle>Edit Marks</DialogTitle>
            <DialogTitle>{get(marksData, 'name', '')}</DialogTitle>
            <DialogTitle>Current Marks: {get(marksData, 'prevMarks', '')}</DialogTitle>

            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
              {' '}
            </Stack>
            <DialogContent>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="marks"
                  label="New Total Marks"
                  style={{ width: '100%' }}
                  value={get(marksData, 'marks', '')}
                  onChange={(e) => {
                    handleMarksDataChange('marks', e);
                  }}
                  required
                />
              </Stack>
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={editMarks} style={{ color: 'white', backgroundColor: 'blue' }}>
                ADD / EDIT
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Container>
    </Page>
  );
}
