import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import moment from 'moment';
import { get, isEmpty } from 'lodash';
import { signupForPublicExam } from 'src/axiosLib/services/user';
import { sessions } from 'src/util/helpers';
import { getPublicExamStartTime } from 'src/axiosLib/services/exam/user';
import Countdown from 'react-countdown';
import { FormProvider } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function UserOwnRegisterForPublicExam() {
  const navigate = useNavigate();

  const [studentInfo, setStudentInfo] = useState({
    validTill: moment().format('DD/MM/YYYY'),
    session: new Date().getFullYear(),
  });

  const [showRegForm, setShowRegForm] = useState(false);

  const [examSummaryFromAPI, setExamSummaryFromAPI] = useState({});

  const [timerForUserReg, setTimerForUserReg] = useState(Date.now() + 100000 * 60);

  const dateOption = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const [isTimerFinished, setIsTimerFinished] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const handleRegister = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    signupForPublicExam(studentInfo, (err, data) => {
      if (!err) {
        data.user.avatar = examId;

        localStorage.setItem('publicExamUserData', JSON.stringify(data.user));

        alert('Successfully Completed Registration');

        generatePageData();
      } else {
        let errorMessage = '';

        for (const errId in err) {
          errorMessage = errorMessage + ', ' + err[errId];
        }

        errorMessage = errorMessage.slice(2);
        alert(errorMessage);
      }
    });

    // console.log(studentInfo)
  };

  const changeRegData = () => {
    setShowRegForm(true);
  };

  const handleChange = (fieldName, event) => {
    // console.log(event.target.value);
    if (fieldName === 'validTill') {
      setStudentInfo({ ...studentInfo, [fieldName]: moment(event.target.value).format('DD/MM/YYYY') });
    } else {
      setStudentInfo({ ...studentInfo, [fieldName]: event.target.value });
    }
  };

  const getUser = () => {
    let userData = JSON.parse(localStorage.getItem('publicExamUserData'));

    if (userData) {
      setStudentInfo(userData);

      // show timer
    } else {
    }
  };

  const setExamDataInLocaStorage = (userData, examId) => {
    let temp = {
      userId: userData._id,
      examId: examId,
    };

    let data = JSON.parse(localStorage.getItem('examData'));

    if (!isEmpty(data)) {
      if (new Date() > new Date(data.cacheLastTime)) {
        localStorage.removeItem('examData');
      }

      if (data.examId != temp.examId) {
        localStorage.removeItem('examData');
      }

      if (data.userId != temp.userId) {
        localStorage.removeItem('examData');
      }
    }

    data = JSON.parse(localStorage.getItem('examData'));

    // if (isEmpty(data) || data.) {
    //
    // } else {
    //   // setExamSummaryToShow(data)
    // }

    console.log(data);
  };

  const generatePageData = () => {
    // localStorage.setItem('publicExamUserData', null);

    let userData = JSON.parse(localStorage.getItem('publicExamUserData'));

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    if (!isEmpty(userData) && userData.avatar != examId) {
      userData = null;
    }

    if (!isEmpty(userData) && userData.avatar == examId) {
      setExamDataInLocaStorage(userData, examId);

      setShowRegForm(false);
    } else {
      setShowRegForm(true);
    }
  };

  const rendererOfUserRegForm = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsTimerFinished(true);
      return <span>Exam has started</span>;
    } else {
      // Render a countdown

      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const timeDiffinMinutes = (start, end) => {
    const diffInMilliSeconds = Math.abs(new Date(end) - new Date(start));
    const seconds = Math.floor(diffInMilliSeconds / 1000).toFixed(2);

    if (new Date(end) < new Date(start)) {
      return 15;
    }

    return seconds;
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const examId = urlParams.get('examId');

    getPublicExamStartTime({ examId: examId }, (err, data) => {
      if (!err) {
        getUser();

        if (new Date() < new Date(get(data, 'examData[0].endTime'))) {
          setExamSummaryFromAPI(data);

          generatePageData();

          setTimerForUserReg(
            Date.now() +
              timeDiffinMinutes(get(data, 'serverTime', new Date()), get(data, 'examData[0].startTime', new Date())) *
                1000
          );
        } else {
          setShowRegForm(false);
          setShowRegForm(false);
        }
      } else {
        // navigate('/login', { replace: true });
        alert('Exam not found');
      }
    });
  }, []);

  return (
    <div>
      {true ? (
        <div>
          {!showRegForm && (
            <div>
              <Card sx={{ width: '98%', marginBottom: '30px', border: '2px solid #800000' }}>
                <CardContent
                  style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h2">অভিনন্দন!</Typography>
                    <Typography variant="h5">
                      তোমার *** {get(examSummaryFromAPI, 'examData[0].name')} *** পরীক্ষার রেজিস্ট্রেশন সম্পন্ন হয়েছে।
                      নিচের টাইমার বক্সে সময় হলে পার্টিসিপেট করার অপশন পেয়ে যাবে।
                    </Typography>
                    <Typography variant="h2">
                      <Button variant="contained" style={{ background: 'blue' }} onClick={() => changeRegData()}>
                        Change My Registration Info
                      </Button>
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              flexDirection: 'row',
              position: '-webkit-sticky',
              top: '10px',
              zIndex: '1000',
            }}
          >
            <Card sx={{ width: '100%', marginBottom: '30px', border: '2px solid #800000' }}>
              <CardContent style={{ backgroundColor: 'beige', color: '#800000', fontWeight: 'bold', fontSize: '20px' }}>
                {!isEmpty(examSummaryFromAPI) && (
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    এক্সাম ডেটঃ{' '}
                    {new Date(get(examSummaryFromAPI, 'examData[0].startTime')).toLocaleDateString('en-US', dateOption)}{' '}
                    । এক্সাম শুরু হলে তোমরা পার্টিসিপেট করতে পারবে। এক্সাম শুরু হতে আর সময় বাকি আছে:
                    <h1>
                      <Countdown date={timerForUserReg} renderer={rendererOfUserRegForm} />
                    </h1>
                    {!showRegForm && isTimerFinished && (
                      <Button
                        variant="contained"
                        component={RouterLink}
                        to={'/publicExam?examId=' + get(examSummaryFromAPI, 'examData[0]._id')}
                      >
                        Participate Exam
                      </Button>
                    )}
                  </div>
                )}

                {isEmpty(examSummaryFromAPI) && (
                  <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <h1>এক্সামের সময় শেষ। আমরা তোমাকে পরবর্তী পরীক্ষায় অংশগ্রহন করার জন্য আমন্ত্রণ জানাচ্ছি।</h1>
                  </div>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      ) : (
        console.log('')
      )}

      {showRegForm == true ? (
        <div>
          <FormProvider methods={methods}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="firstName"
                  label="First name"
                  value={get(studentInfo, 'firstname', '')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    handleChange('firstname', e);
                  }}
                  required
                />
                <TextField
                  name="lastName"
                  label="Last name"
                  value={get(studentInfo, 'lastname', '')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    handleChange('lastname', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="username"
                  label="Full Name"
                  value={get(studentInfo, 'username', '')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    handleChange('username', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="phone"
                  label="Student Phone"
                  value={get(studentInfo, 'phone', '')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    handleChange('phone', e);
                  }}
                  required
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <TextField
                  name="guardianSms"
                  label="Guardian Phone"
                  value={get(studentInfo, 'guardianSMS', '')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    handleChange('guardianSMS', e);
                  }}
                  required
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  name="address"
                  label="School / College"
                  value={get(studentInfo, 'address', '')}
                  style={{ width: '100%' }}
                  onChange={(e) => {
                    handleChange('address', e);
                  }}
                  required
                />
              </Stack>

              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <FormControl fullWidth required style={{ width: '50%' }}>
                  <InputLabel id="sessiom-label">HSC year</InputLabel>
                  <Select
                    id="session-label"
                    value={get(studentInfo, 'session', new Date().getFullYear())}
                    label="Session"
                    onChange={(e) => {
                      handleChange('session', e);
                    }}
                  >
                    {sessions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Button variant="contained" style={{ background: 'blue' }} onClick={() => handleRegister()}>
                REGISTER FOR EXAM
              </Button>
            </Stack>
          </FormProvider>
        </div>
      ) : (
        console.log('')
      )}
    </div>
  );
}
